import { useTranslation } from 'react-i18next';

import { Box } from 'theme-ui';

import { useInstructors, useCoursesAll } from '@youga/youga-client-api';

import Text from '../../01_atoms/Text/Text';
import Carousel from '../../02_molecules/Carousel/Carousel';

import CourseCarouselItem from '../CourseCarouselItem/CourseCarouselItem';
import i18n from '../../../i18n/i18n';

function YourCourses() {
  const { t } = useTranslation();

  const { data: instructorsData } = useInstructors();
  const courses = useCoursesAll(i18n.language);

  console.log('courses', { courses : courses.map(c => {
    return {
      id: c.id,
      title: c.title,
      sessions: c.sessions,
      thumbnail: c.thumbnail,
      thumbnailHq: c.thumbnailHq,
    }
  })});

  return (
    <>
      <Text variant="h2light" sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}>
        {t('KURSE_F_R_DICH')}
      </Text>
      <Box
        style={{
          position: 'relative',
          // We need to extend the width a bit, to allow the shadow to be visible
          // This space will be added afterwards as a padding-left at the items
          marginLeft: '-20px',
          marginRight: '-20px',
          width: 'calc(100% + 40px)',
        }}
      >
        <Carousel
          imageHeight={180}
          hideControlsOnTouchDevices
          hideControlShadow
          swipeLeftStyle={{
            top: 7,
            left: 2,
          }}
          swipeRightStyle={{
            top: 7,
            right: 2,
          }}
        >
          {courses.map((course, index) => {
            return (
              <CourseCarouselItem
                target={`/app/course-detail/${course.id}`}
                key={`coursecarousel-item-${course.id}`}
                title={course.title}
                content={course.subtitle}
                sessions={course.sessions.length}
                thumbnailUrl={course.thumbnail || ''}
                thumbnailHqUrl={course.thumbnailHq || ''}
                labels={course.labels}
                variant="all"
                level={course.level}
                preview={course.preview}
                instructorId={course.instructor}
                courseId={course.id}
                instructor={
                  instructorsData?.instructors[course.instructor]?.name ?? ''
                }
                isLastElement={courses.length - 1 === index}
              />
            );
          })}
        </Carousel>
      </Box>
    </>
  );
}

export default YourCourses;
